import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

import {
  FlexContainer,
  palette,
  CommonTable,
  TableContainer,
  TablePagination,
  Loading,
  SearchInput,
  IconButton,
  AddIcon,
  Button,
  MobileTable,
} from "@busie/ui-kit";
import { ChefBooking, ResultWithTotal } from "@busie/api";
import {
  StatusBadge,
  OrganizationLogo,
  SPAB,
  IDTableCell,
} from "~/QuotesAndBookingsPage/shared/ui";
import {
  QuoteAndBookingStatus,
  User,
  centsToDollars,
  currencyFormatter,
  datetimeFormatter,
  dayjsExt,
  metersToMiles,
} from "@busie/utils";
import {
  ListQuotesQuery,
  SortQuotesQuery,
  useFetchQuoteIntegrations,
} from "~/QuotesAndBookingsPage/model";
import { ITEMS_PER_PAGE } from "./model";
import { Filter } from "~/QuotesAndBookingsPage/features";
import { TableControls } from "~/QuotesAndBookingsPage/entity";
import { useDebouncedCallback } from "@busie/core";
import { useAuth0 } from "@auth0/auth0-react";
import { getAssigneeFilterValue } from "~/QuotesAndBookingsPage/shared/lib/get-assignee-filter-value";

interface Props {
  bookings: ResultWithTotal<ChefBooking>;
  currentPage: number;
  isLoading?: boolean;
  defaultQuery?: ListQuotesQuery;
  onPageChange: (page: number) => void;
  onQueryChange: (query: Omit<ListQuotesQuery, "search">) => void;
  onSortChange: (sort: SortQuotesQuery) => void;
  onRowClick: (id: string) => void;
  onAddNewQuote: () => void;
  onSearchChange: (search: string) => void;
}

export const Table: React.FC<Props> = ({
  bookings,
  currentPage,
  isLoading = false,
  defaultQuery = {
    status: [],
    dispatchLocations: [],
    startDate: [null, null],
    createdDate: [null, null],
    assigneeId: null,
    search: "",
  },
  onPageChange,
  onQueryChange,
  onSortChange,
  onRowClick,
  onAddNewQuote,
  onSearchChange,
}) => {
  const isMobile = useMediaQuery("@media (max-width: 960px)");
  const isTablet = useMediaQuery("@media (max-width: 1136px)");

  const { user } = useAuth0<User>();

  const {
    data: integrations,
    isLoading: isIntegrationsLoading,
    isFetching: isIntegrationsFetching,
  } = useFetchQuoteIntegrations();

  const [ordering, setOrdering] = useState<{
    column: string;
    order: "asc" | "desc";
  }>({
    column: "CREATED_AT",
    order: "desc",
  });

  const tableConfig = {
    checkboxKeys: bookings.data.map((booking) => booking._id.toString()),
    columnNames: [
      { id: "_ID", name: "Booking ID", ordered: false },
      { id: "CUSTOMER", name: "Customer", ordered: false },
      { id: "GROUP", name: "Group", ordered: false },
      { id: "EXPERIENCES", name: "Trips", ordered: false },
      { id: "METERS", name: "Distance", ordered: false },
      { id: "CREATED_AT", name: "Created", ordered: true },
      { id: "PRICE", name: "Price", ordered: false },
      { id: "BALANCE", name: "Balance", ordered: false },
      { id: "INFORMATION", name: "Information", ordered: false },
      { id: "STATUS", name: "Status", ordered: false },
    ],
    data: bookings.data.map((booking) => ({
      id: (
        <IDTableCell
          id={booking._id.toString()}
          idShortener={(id) => id.split("-")[0]}
          type="booking"
        />
      ),
      customer: booking.QUOTE.MAIN_CONTACT?.NAME || booking.QUOTE.CONTACT_NAME,
      group: booking.QUOTE.CUSTOMER_GROUP?.NAME,
      numberOfTrips: booking.QUOTE.EXPERIENCES.length,
      distance: `${metersToMiles(
        booking.QUOTE.EXPERIENCES.reduce((acc, curr) => acc + curr.METERS, 0)
      )} mi`,
      createdAt: datetimeFormatter(dayjsExt(booking.CREATED_AT), "L LT"),
      price: currencyFormatter(centsToDollars(booking.QUOTE.PRICE)),
      balance: currencyFormatter(centsToDollars(booking.BALANCE)),
      information: (
        <FlexContainer direction="row" justify="flex-start" align="center">
          {[
            [
              booking.QUOTE.QUOTE_INTEGRATION_ID,
              isIntegrationsFetching || isIntegrationsLoading ? (
                <Loading size={24} padding="0px" />
              ) : (
                <OrganizationLogo
                  src={
                    integrations?.organizationLogos[
                      booking.QUOTE.QUOTE_INTEGRATION?.SOURCE_ID ===
                      user?.org_id
                        ? booking.QUOTE.QUOTE_INTEGRATION?.DESTINATION_ID || ""
                        : booking.QUOTE.QUOTE_INTEGRATION?.SOURCE_ID || ""
                    ] || ""
                  }
                />
              ),
            ],
            [
              booking.QUOTE.EXPERIENCES.some((experience) => experience.SPAB),
              <SPAB />,
            ],
          ].map(([display, component]) => (display ? component : null))}
        </FlexContainer>
      ),
      status: (
        <StatusBadge type={booking.STATUS} isTablet={isTablet || isMobile} />
      ),
    })),
  };

  useEffect(() => {
    onSortChange({
      [ordering.column]: ordering.order,
    });
  }, [ordering, onSortChange]);

  const debouncedSearch = useDebouncedCallback(
    (search: string) => onSearchChange(search),
    700
  );

  const Table = isMobile ? MobileTable : CommonTable;

  return (
    <TableContainer isMobile={isMobile} sx={{ marginTop: "16px" }}>
      <TableControls
        activeView="bookings"
        filterSlot={
          <Filter
            onChange={(value) =>
              onQueryChange({
                status: value.statuses,
                dispatchLocations: value.dispatches,
                startDate: value.pickupDates,
                createdDate: value.createdDates,
                assigneeId: value.assigneeId,
              })
            }
            statusOptions={[
              { name: "Pending", value: QuoteAndBookingStatus.PENDING },
              { name: "Confirmed", value: QuoteAndBookingStatus.CONFIRMED },
              { name: "Paid", value: QuoteAndBookingStatus.PAID },
              { name: "Canceled", value: QuoteAndBookingStatus.CANCELED },
            ]}
            defaultValues={{
              statuses: defaultQuery.status || [],
              dispatches: defaultQuery.dispatchLocations || [],
              pickupDates: defaultQuery.startDate || [null, null],
              createdDates: defaultQuery.createdDate || [null, null],
              assigneeId: getAssigneeFilterValue(defaultQuery.assigneeId),
            }}
          />
        }
        addQuoteSlot={
          isMobile ? (
            <IconButton
              icon={<AddIcon color={palette.black.main} />}
              highlightOnHover={true}
              style={{
                backgroundColor: palette.black.plus100,
                border: `1px solid ${palette.black.plus70}`,
                borderRadius: "6px",
              }}
              onClick={onAddNewQuote}
            />
          ) : (
            <FlexContainer
              direction="row"
              fullWidth
              justify="flex-end"
              align="center"
            >
              <Button
                startIcon={<AddIcon />}
                typestyle="primary"
                onClick={onAddNewQuote}
              >
                Add new quote
              </Button>
            </FlexContainer>
          )
        }
        searchSlot={
          <SearchInput
            placeholder="Find the needle in the haystack"
            search={debouncedSearch}
            fullWidth
          />
        }
      />

      <div className="table-container">
        {(isLoading && <Loading />) || (
          <Table
            checkboxKeys={tableConfig.checkboxKeys}
            columnNames={tableConfig.columnNames}
            data={tableConfig.data}
            onRowClick={onRowClick}
            onSort={(id) =>
              setOrdering((curr) => ({
                column: id,
                order:
                  id === curr.column
                    ? (curr.order === "desc" && "asc") || "desc"
                    : "desc",
              }))
            }
            order={ordering.order}
            orderBy={ordering.column}
            hideColumns={isTablet}
            columnsToHide={[6]}
          />
        )}
      </div>

      <TablePagination
        outlined
        itemsTotal={bookings.total}
        pageNumber={currentPage}
        perPage={ITEMS_PER_PAGE}
        currentOffset={ITEMS_PER_PAGE * (currentPage - 1)}
        nextOffset={Math.min(currentPage * ITEMS_PER_PAGE, bookings.total)}
        onNextClick={() => onPageChange(currentPage + 1)}
        onPrevClick={() => onPageChange(currentPage - 1)}
        onToEndClick={() =>
          onPageChange(Math.ceil(bookings.total / ITEMS_PER_PAGE))
        }
        onToBeginningClick={() => onPageChange(1)}
      />
    </TableContainer>
  );
};
