import cx from "classnames";
import { ChefQuote, Experience } from "@busie/api";
import {
  CanceledStatusText,
  CompletedStatusText,
  ConfirmedStatusText,
  DrawerDetailsBlock,
  FlexContainer,
  H3,
  IconButton,
  Loading,
  LocationIcon,
  PathIcon,
  PendingStatusText,
  RoundTripIcon,
  RulerIcon,
  TimeIcon,
  TripIcon,
  VehicleGroupIcon,
  palette,
} from "@busie/ui-kit";
import {
  QuoteAndBookingStatus,
  centsToDollars,
  currencyFormatter,
  dayjsExt,
  metersToMiles,
} from "@busie/utils";
import { Tooltip } from "@mui/material";
import React from "react";
import { BlockContainer } from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  quote: ChefQuote;
  onClickTrip: (trip: Experience) => void;
}

const getTripStatusText = (status: QuoteAndBookingStatus) => {
  switch (status) {
    case QuoteAndBookingStatus.PENDING:
      return <PendingStatusText />;
    case QuoteAndBookingStatus.CONFIRMED:
      return <ConfirmedStatusText />;
    case QuoteAndBookingStatus.CANCELED:
      return <CanceledStatusText />;
    case QuoteAndBookingStatus.COMPLETE:
      return <CompletedStatusText />;
    default:
      return <>Unable to determine status</>;
  }
};

const getTripTypeHeaderContent = (trip: Experience) => {
  const pickup = trip.LEGS[0].DESTINATION_LOCATION.ADDRESS;
  const destination = trip.LEGS[trip.LEGS.length - 1].START_LOCATION.ADDRESS;

  const tripType = pickup === destination ? "Round Trip" : "One Way";

  return (
    <Tooltip arrow placement="top" title={tripType}>
      <div style={{ display: "flex" }}>
        {tripType === "Round Trip" ? <RoundTripIcon /> : <PathIcon />}
      </div>
    </Tooltip>
  );
};

export const List: React.FC<React.PropsWithChildren<Props>> = ({
  quote,
  onClickTrip,
}) => {
  return (
    <DrawerDetailsBlock name="Trips" collapsible>
      {quote.EXPERIENCES.map((trip, index) =>
        // when trips are updated there are edge cases which cause the legs array to be empty. We'll display a loading indicator until the legs are populated.
        // TBD on why this is happening.
        trip.LEGS.length ? (
          <FlexContainer
            key={`trip-${index}`}
            justify="space-evenly"
            align="center"
            fullWidth
            direction="column"
          >
            <BlockContainer sx={{ width: "100%" }}>
              <FlexContainer
                direction="row"
                justify="space-between"
                wrap="wrap"
                fullWidth
                align="center"
                sx={{ marginBotton: "13px" }}
              >
                <H3 color={palette.black.plus30}>{`Trip ${index + 1}`}</H3>
                {getTripStatusText(trip.STATUS as QuoteAndBookingStatus)}

                {getTripTypeHeaderContent(trip)}

                <Tooltip
                  arrow
                  placement="top"
                  title={`${trip.LEGS.length - 1} stops`}
                >
                  <div style={{ display: "flex" }}>
                    <FlexContainer direction="row" columnGap={1} align="center">
                      <H3 color={palette.black.plus30}>
                        {trip.LEGS.length - 1}
                      </H3>
                      <LocationIcon />
                    </FlexContainer>
                  </div>
                </Tooltip>

                <Tooltip
                  arrow
                  placement="top"
                  title={`${trip.RESERVATION_IDS.length} vehicles`}
                >
                  <div style={{ display: "flex" }}>
                    <FlexContainer direction="row" columnGap={1} align="center">
                      <H3 color={palette.black.plus30}>
                        {trip.RESERVATION_IDS.length}
                      </H3>
                      <VehicleGroupIcon />
                    </FlexContainer>
                  </div>
                </Tooltip>

                <Tooltip
                  arrow
                  placement="top"
                  title={`${metersToMiles(trip.METERS)} miles`}
                >
                  <div style={{ display: "flex" }}>
                    <FlexContainer direction="row" columnGap={1} align="center">
                      <H3 color={palette.black.plus30}>
                        {metersToMiles(trip.METERS)}
                      </H3>
                      <RulerIcon />
                    </FlexContainer>
                  </div>
                </Tooltip>

                <Tooltip
                  arrow
                  placement="top"
                  title={`${
                    trip.HOURS < 1 ? "< 1" : "~" + Math.ceil(trip.HOURS)
                  } hours of travel time`}
                >
                  <div style={{ display: "flex" }}>
                    <FlexContainer direction="row" columnGap={1} align="center">
                      <H3 color={palette.black.plus30}>
                        {trip.HOURS < 1 ? "< 1" : "~" + Math.ceil(trip.HOURS)}
                      </H3>
                      <TimeIcon />
                    </FlexContainer>
                  </div>
                </Tooltip>

                <FlexContainer direction="row" align="center" columnGap={1}>
                  <H3>{currencyFormatter(centsToDollars(trip.PRICE))}</H3>
                  <IconButton
                    icon={<TripIcon />}
                    onClick={() => onClickTrip(trip)}
                  />
                </FlexContainer>
              </FlexContainer>

              <FlexContainer justify="space-evenly" columnGap={2}>
                <div
                  className={cx({
                    departures: true,
                    end: false,
                    start: true,
                    last: false,
                    first: true,
                  })}
                  style={{ width: "100%" }}
                >
                  <div className={cx({ origin: true, start: true })}>
                    {trip.LEGS[0]?.DESTINATION_LOCATION.ADDRESS}
                    <div
                      style={{
                        marginTop: "4px",
                        color: palette?.black?.plus30,
                        fontWeight: 500,
                        fontSize: "10px",
                      }}
                    >
                      Start Date:{" "}
                      {dayjsExt(trip.START_DATE).format("dddd, L LT")}
                    </div>
                  </div>

                  <div className={cx({ destination: true, end: true })}>
                    {trip.LEGS[trip.LEGS.length - 1]?.START_LOCATION.ADDRESS}
                    <div
                      style={{
                        marginTop: "4px",
                        color: palette?.black?.plus30,
                        fontWeight: 500,
                        fontSize: "10px",
                      }}
                    >
                      End Date: {dayjsExt(trip.END_DATE).format("dddd, L LT")}
                    </div>
                  </div>
                </div>
              </FlexContainer>
            </BlockContainer>
          </FlexContainer>
        ) : (
          <Loading size={24} padding="0px" />
        )
      )}
    </DrawerDetailsBlock>
  );
};
