import CustomerForm from "./customerForm";
import TripForm from "./tripForm";
import RouteForm from "./routeForm";
import DeparturesForm from "./departuresForm";
import PageNavigation from "./pageNaviagtion";
import { CreateTripFormData } from "@busie/api";
import { toHours } from "@busie/api";

import { getTripLegs } from "./helpers";

class Store {
  quoteId: string | undefined = undefined;
  pageNavigation = new PageNavigation();
  customerForm = new CustomerForm();
  tripForm = new TripForm();
  routeForm = new RouteForm();
  departuresForm = new DeparturesForm();

  public resetAll() {
    this.quoteId = undefined;
    this.pageNavigation = new PageNavigation();
    this.customerForm = new CustomerForm();
    this.tripForm = new TripForm();
    this.routeForm = new RouteForm();
    this.departuresForm = new DeparturesForm();
  }

  public getTripFormData(orgId?: string): CreateTripFormData {
    return {
      quoteId: this.quoteId || undefined,
      organizationId: orgId || "",
      passengers: this.tripForm.passengers.amount || 0,
      wheelchairs: this.tripForm.passengers.accessibleSeating || 0,
      spab: this.tripForm.spab || false,
      additionalInformation: this.tripForm.additionalInfo,
      legs: getTripLegs(this.departuresForm),
      tripPlannerId: this.customerForm.customer?.id || "",
      tripPlannerGroupId: this.customerForm.customerGroup?.id || "",
      meters: this.departuresForm?.route?.totalDistance || 0,
      hours: toHours(this.departuresForm?.route?.estimateTravelTime),
    };
  }

  public setQuoteId(id: string) {
    this.quoteId = id;
  }
}

export default new Store();
