import React, { FC } from "react";
import { observer } from "mobx-react";
import { Stepper } from "@busie/ui-kit";

import { getIcon } from "./getIcon";
import FormBlock, { StepName } from "./FormBlock";
import store from "./store";
import QuoteFormStyled from "./styled/QuoteFormStyled";
import ProviderNameLabel from "./components/ProviderNameLabel";

interface Props {
  embedded?: boolean;
  customersAuthToken?: string;
  pathfinderAuthToken?: string;
  tripsAuthToken?: string;
  quotesAuthToken?: string;
  rateServiceAuthToken?: string;
  guestAuthToken?: string;
  embeddedFormOrgId?: string;
  stepList: StepName[];
  quoteId?: string;
}

export const QuoteForm: FC<React.PropsWithChildren<Props>> = observer(
  ({
    customersAuthToken,
    pathfinderAuthToken,
    tripsAuthToken,
    quotesAuthToken,
    rateServiceAuthToken,
    guestAuthToken,
    stepList,
    embedded,
    embeddedFormOrgId,
    quoteId,
  }) => {
    const nav = store.pageNavigation;

    nav.stepList = stepList;

    React.useEffect(() => {
      return () => store.resetAll();
    }, []);

    return (
      <QuoteFormStyled embedded={embedded}>
        <Stepper step={nav.currentStep} getIcon={getIcon} stepList={stepList} />
        <FormBlock
          customersAuthToken={customersAuthToken || ""}
          pathfinderAuthToken={pathfinderAuthToken || ""}
          tripsAuthToken={tripsAuthToken || ""}
          quotesAuthToken={quotesAuthToken || ""}
          rateServiceAuthToken={rateServiceAuthToken || ""}
          guestAuthToken={guestAuthToken || ""}
          embedded={embedded}
          embeddedFormOrgId={embeddedFormOrgId}
          step={nav.currentStep}
          goNext={nav.goNext}
          goPrev={nav.goPrev}
          setCurrentStep={nav.setCurrentStep}
          setNextStep={(index) => nav.setNextStep(index)}
          stepList={stepList}
          quoteId={quoteId}
        />
        {embedded && <ProviderNameLabel />}
      </QuoteFormStyled>
    );
  }
);
