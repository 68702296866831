import axios from "axios";
import qs from "qs";
import {
  env,
  dayjsExt,
  Booking,
  CompanyHoliday,
  Quote,
  QuoteAndBookingStatus,
  QuotesAndBookingsSettings,
  SendContractDto,
  VoidedContractInfo,
  QuotesIntegration,
} from "@busie/utils";
import { BookingsResponse, QuotesResponse } from "../../dataTypes";

const QUOTES_AND_BOOKINGS_SERVICE_API_URL = `${env(
  "BUSIE_QUOTES_AND_BOOKINGS_SERVICE_API_URL"
)}/qnb`;

export interface CreatePaymentLinkData {
  amount: number;
  contactEmail: string;
  bookingIds: string[];
}

export interface CreateQuoteData {
  locationId: string;
  organizationId: string;
  startDate: string;
  customerGroupId: string;
  customerGroupName: string;
  experienceId: string;
  price: number;
  contactId: string;
  sourceId?: string;
  quotesIntegrationId?: string;
}

export const createQuote = async (
  authToken: string,
  formData: CreateQuoteData
): Promise<Quote> => {
  const { data } = await axios.post<Quote>(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes`,
    formData,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export interface QuoteSearchParams {
  contactName?: string;
  contactEmail?: string;
  customerGroupName?: string;
  // quoteId?: string;
}
export interface BookingSearchParams {
  contactName?: string;
  contactEmail?: string;
  customerGroupName?: string;
  quoteId?: string;
}

export const fetchQuotes = async (
  authToken: string,
  params: {
    itemsPerPage?: number;
    page?: number;
    status?: QuoteAndBookingStatus | QuoteAndBookingStatus[];
    locationId?: string | null;
    pickupDateFrom?: Date | null;
    pickupDateTo?: Date | null;
  } & QuoteSearchParams
): Promise<QuotesResponse> => {
  const queryParams = qs.stringify(
    {
      status: params.status,
      locationId: params.locationId,
      take: params.itemsPerPage,
      skip:
        params.itemsPerPage && params.page && params.page > 1
          ? (params.page - 1) * params.itemsPerPage
          : 0,
      pickupDateFrom: params.pickupDateFrom,
      pickupDateTo: params.pickupDateTo,
      contactName: params.contactName,
      contactEmail: params.contactEmail,
      customerGroupName: params.customerGroupName,
    },
    { addQueryPrefix: true, arrayFormat: "repeat", skipNulls: true }
  );

  const { data } = await axios.get(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes${queryParams}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const fetchQuotesIntegrations = async (
  authToken: string
): Promise<QuotesIntegration> => {
  const { data } = await axios.get<QuotesIntegration>(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes-integration`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const fetchQuote = async (
  authToken: string,
  id: string
): Promise<Quote> => {
  const { data } = await axios.get(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const updateQuoteStatus = async (
  authToken: string,
  id: string,
  status: string,
  reasonForDecline?: string
) => {
  const payload: { status: string; reasonForDecline?: string } = { status };

  if (status === "DECLINED" && !!reasonForDecline) {
    payload.reasonForDecline = reasonForDecline;
  }

  const { data } = await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/status`,
    payload,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const sendQuote = async (authToken: string, id: string) => {
  const { data } = await axios.post(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/send-confirmation-by-email`,
    { id },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const deleteQuote = async (
  authToken: string,
  id: string
): Promise<Quote> => {
  const { data } = await axios.delete(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const fetchBooking = async (
  authToken: string,
  id: string
): Promise<Booking> => {
  const { data } = await axios.get(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/bookings/${id}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const fetchBookings = async (
  authToken: string,
  params: {
    itemsPerPage?: number;
    page?: number;
    status?: QuoteAndBookingStatus | QuoteAndBookingStatus[];
    locationId?: string | null;
    pickupDateFrom?: Date | null;
    pickupDateTo?: Date | null;
  } & BookingSearchParams
): Promise<BookingsResponse> => {
  const queryParams = qs.stringify(
    {
      status: params.status,
      locationId: params.locationId,
      take: params.itemsPerPage,
      skip:
        params.itemsPerPage && params.page && params.page > 1
          ? (params.page - 1) * params.itemsPerPage
          : 0,
      pickupDateFrom: params.pickupDateFrom,
      pickupDateTo: params.pickupDateTo,
      contactName: params.contactName,
      contactEmail: params.contactEmail,
      customerGroupName: params.customerGroupName,
      quoteId: params.quoteId,
    },
    { addQueryPrefix: true, arrayFormat: "repeat", skipNulls: true }
  );
  const { data } = await axios.get(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/bookings${queryParams}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const createBooking = async (
  authToken: string,
  quoteId: string
): Promise<Booking> => {
  const { data } = await axios.post(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/bookings`,
    {
      quoteId,
    },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const updateBookingStatus = async (
  authToken: string,
  id: string,
  status: string
) => {
  const { data } = await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/bookings/${id}/status`,
    { status },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const fetchQnBSettings = async (
  authToken: string,
  id: string
): Promise<QuotesAndBookingsSettings> => {
  const { data } = await axios.get(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/settings/${id}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const patchQnBSetting = async (
  setting: string,
  authToken: string,
  id: string,
  value: string | number | boolean
) => {
  switch (setting) {
    case "email":
      return patchEmail(authToken, id, value as string);
    case "defaultSignerName":
      return patchDefaultSignerName(authToken, id, value as string);
    case "lastMinuteSurge":
      return patchLastMinuteSurge(authToken, id, value as number);
    case "fuelSurcharge":
      return patchFuelSurcharge(authToken, id, value as number);
    case "taxSurcharge":
      return patchTaxSurcharge(authToken, id, value as number);
    case "enablePlatformPricingMarkup":
      return patchEnablePlatformPricingMarkup(authToken, id, value as boolean);
    default:
      return;
  }
};

export const patchEmail = async (
  authToken: string,
  id: string,
  email: string
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/settings/${id}/email`,
    { email },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchDefaultSignerName = async (
  authToken: string,
  id: string,
  defaultSignerName: string
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/settings/${id}/default-signer-name`,
    { defaultSignerName },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchLastMinuteSurge = async (
  authToken: string,
  id: string,
  lastMinuteSurge: number
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/settings/${id}/surge`,
    { lastMinuteSurge },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchFuelSurcharge = async (
  authToken: string,
  id: string,
  fuelSurcharge: number
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/settings/${id}/fuel-surcharge`,
    { fuelSurcharge },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchTaxSurcharge = async (
  authToken: string,
  id: string,
  taxSurcharge: number
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/settings/${id}/tax-surcharge`,
    { taxSurcharge },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchEnablePlatformPricingMarkup = async (
  authToken: string,
  id: string,
  enablePlatformPricingMarkup: boolean
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/settings/${id}/enable-platform-pricing-markup`,
    { enablePlatformPricingMarkup },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const updateEnableAssigneeContractSigner = async (
  authToken: string,
  id: string,
  enableAssigneeContractSigner: boolean
) => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/settings/${id}/enable-assignee-contract-signer`,
    { enableAssigneeContractSigner },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const fetchCompanyHolidays = async (
  authToken: string,
  id: string
): Promise<CompanyHoliday[]> => {
  const { data } = await axios.get(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/holidays?organizationId=${id}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  // Backend provides paginated results, but frontend doesn't handle it (yet?)
  return data.resultSet;
};

interface HolidayPayload {
  id?: string;
  name: string;
  organizationId: string;
  holidayDate?: string;
  holidayRange?: {
    from: string;
    to: string;
  };
  repeat: string;
}

export const patchCompanyHoliday = async (
  authToken: string,
  organizationId: string,
  companyHoliday: Pick<
    CompanyHoliday,
    "id" | "name" | "holidayRangeFrom" | "holidayRangeTo" | "repeat"
  >
): Promise<CompanyHoliday> => {
  const payload: HolidayPayload = {
    id: companyHoliday.id,
    name: companyHoliday.name,
    organizationId,
    repeat: companyHoliday.repeat,
  };
  if (
    dayjsExt(companyHoliday.holidayRangeFrom).isSame(
      dayjsExt(companyHoliday.holidayRangeTo),
      "day"
    )
  ) {
    payload.holidayDate = dayjsExt(companyHoliday.holidayRangeFrom)
      .startOf("day")
      .toISOString();
  } else {
    payload.holidayRange = {
      from: companyHoliday.holidayRangeFrom,
      to: companyHoliday.holidayRangeTo,
    };
  }
  const { data } = await axios.put<CompanyHoliday>(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/holidays`,
    payload,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const createCompanyHoliday = async (
  authToken: string,
  organizationId: string,
  companyHoliday: Pick<
    CompanyHoliday,
    "holidayRangeFrom" | "holidayRangeTo" | "name" | "repeat"
  >
): Promise<CompanyHoliday> => {
  const payload: HolidayPayload = {
    name: companyHoliday.name,
    organizationId,
    repeat: companyHoliday.repeat,
  };
  if (
    dayjsExt(companyHoliday.holidayRangeFrom).isSame(
      dayjsExt(companyHoliday.holidayRangeTo),
      "day"
    )
  ) {
    payload.holidayDate = dayjsExt(companyHoliday.holidayRangeFrom)
      .startOf("day")
      .toISOString();
  } else {
    payload.holidayRange = {
      from: companyHoliday.holidayRangeFrom,
      to: companyHoliday.holidayRangeTo,
    };
  }
  const { data } = await axios.post<CompanyHoliday>(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/holidays`,
    payload,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const deleteCompanyHoliday = async (
  authToken: string,
  id: string
): Promise<void> => {
  await axios.delete(`${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/holidays/${id}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const sendContract = async (
  authToken: string,
  payload: SendContractDto,
  bookingId: string
): Promise<void> => {
  await axios.post(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/bookings/${bookingId}/send-contract`,
    payload,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const voidContract = async (
  authToken: string,
  payload: VoidedContractInfo,
  bookingId: string
): Promise<void> => {
  await axios.post(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/bookings/${bookingId}/void-contract`,
    payload,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const createPaymentLink = async (
  authToken: string,
  createPaymentLinkData: CreatePaymentLinkData
): Promise<void> => {
  await axios.post(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/bookings/payment-reminder`,
    createPaymentLinkData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchQuotePrice = async (
  authToken: string,
  id: string,
  price: number
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/price`,
    { price },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const createIntegration = async (
  authToken: string,
  destinationId: string,
  revenueShareConfigId: string
): Promise<void> => {
  await axios.post(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes-integration`,
    {
      destinationId,
      revenueShareConfigId,
      ttl: 24,
      ttlWeekend: 72,
    },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchQuoteFuelSurcharge = async (
  authToken: string,
  id: string,
  fuelSurcharge: number
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/fuel-surcharge`,
    { fuelSurcharge },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchQuoteTaxSurcharge = async (
  authToken: string,
  id: string,
  taxSurcharge: number
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/tax-surcharge`,
    { taxSurcharge },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const patchQuoteLastMinuteSurgeCharge = async (
  authToken: string,
  id: string,
  lastMinuteSurgeCharge: number
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/last-minute-surge`,
    { lastMinuteSurgeCharge },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const updateQuoteEnablePlatformPricingMarkup = async (
  authToken: string,
  id: string,
  enablePlatformPricingMarkup: boolean
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/enable-platform-pricing-markup`,
    { enablePlatformPricingMarkup },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

/**
 * @deprecated
 * @param authToken
 * @param id
 * @param contactName
 * @param contactEmail
 */
export const patchQuoteContactData = async (
  authToken: string,
  id: string,
  contactName: string,
  contactEmail: string
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/contact-info`,
    { contactName, contactEmail },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export interface UpdateQuoteMainContactData {
  contactId: string;
  contactName: string;
  contactEmail: string;
}

export const updateQuoteMainContact = async (
  authToken: string,
  id: string,
  data: UpdateQuoteMainContactData
) => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/contact-info`,
    data,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export interface UpdateQuoteAssigneeData {
  assigneeId: string | null;
}

export const updateQuoteAssignee = async (
  authToken: string,
  id: string,
  data: UpdateQuoteAssigneeData
): Promise<void> => {
  await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes/${id}/assignee`,
    data,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};
