export interface IngredientGroup {
  name: string;
  type: string;
  checked: boolean;
  ingredients: Ingredient[];
}

export type CookbookValue = Record<CookbookType, string>;

export interface RawIngredient {
  _recipeId: string;
  _type: IngredientType;
  _value: string | number;
  _name: string;
  _id: string;
}

export interface BaseIngredient {
  recipeId: string;
  type: IngredientType;
  value: string | number;
  name: string;
}

export interface Ingredient extends BaseIngredient {
  id: string;
}

export interface Rule {
  id: string;
  recipeId: string;
  type: RuleType;
  value: string;
}
export interface RawRecipe {
  _id: string;
  _name: string;
  _rule: Rule;
  _ingredients: RawIngredient[];
  _cookbookId: string;
  _dispatches: [];
  _organization: string;
}
export interface Recipe {
  id: string;
  name: string;
  rule: Rule;
  ingredients: Ingredient[];
  cookbookId: string;
  dispatches: [];
  organization: string;
}

export interface RecipesMap {
  [id: string]: Recipe;
}

export interface RawCookbook {
  _id: string;
  _menuId: string;
  _name: string;
  _type: CookbookType;
  _customerName: string;
  _recipes: RawRecipe[];
  _rulePriority: RuleType[];
}

export interface Cookbook {
  id: string;
  menuId: string;
  name: string;
  type: CookbookType;
  customerName: string;
  recipes: Recipe[];
  rulePriority: RuleType[];
}

export interface Menu {
  _id: string;
  _organization: string;
}

export type RuleType =
  | "DATE"
  | "TOTAL_MILES"
  | "DAILY_MILES"
  | "LEG_MILES"
  | "TOTAL_TIME"
  | "DAILY_TIME"
  | "LEG_TIME"
  | "DAY_OF_WEEK"
  | "MONTH"
  | "STANDARD";

export type IngredientType =
  | "LIVE_MILES"
  | "HOURLY"
  | "LEG_MINIMUM"
  | "DEAD_HEAD_MILES"
  | "PER_NIGHT"
  | "PER_DAY_NO_TRAVEL"
  | "EXTRA_DRIVER_FEE"
  | "FLAT_LEG_RATE"
  | "FLAT_TRIP_RATE"
  | "FLAT_DAY_RATE"
  | "DAILY_MINIMUM";

export type CookbookType =
  | "ORGANIZATION"
  | "CUSTOMER"
  | "VEHICLE_TYPE"
  | "DISPATCH_LOCATION";

export const COOKBOOK_TYPE_LABELS = {
  ORGANIZATION: "Organization",
  VEHICLE_TYPE: "Vehicle Type",
  CUSTOMER: "Customer",
  DISPATCH_LOCATION: "Dispatch Location",
};

export const INGREDIENT_TYPE_LABELS: { [Key in IngredientType]: string } = {
  LIVE_MILES: "Live miles",
  HOURLY: "Hourly cost",
  DAILY_MINIMUM: "Daily minimum",
  LEG_MINIMUM: "Leg minimum",
  DEAD_HEAD_MILES: "Deadhead miles",
  PER_NIGHT: "Per night",
  PER_DAY_NO_TRAVEL: "Per day “without travel”",
  EXTRA_DRIVER_FEE: "Per extra driver",
  FLAT_LEG_RATE: "Per leg",
  FLAT_TRIP_RATE: "Per trip",
  FLAT_DAY_RATE: "Flat day rate",
};

export type IngredientListType = "base" | "side";

export type IngredientList = {
  [listType in IngredientListType]: IngredientGroup[];
};

export interface RateRecipe {
  id?: string;
  name: string;
  organization: string;
  dispatches: string[];
  cookbookId: string;
  cookbook?: RateCookbook;
  rule?: RateRule;
  ingredients?: RateIngredient[];
}

export interface RateCookbook {
  id?: string;
  menuId: string;
  menu?: RateMenu;
  recipes: RateRecipe[];
  rulePriority: string[];
}

export interface RateRule {
  id?: string;
  type: string;
  value: string | number | Date;
  recipeId?: string;
}

export interface RateIngredient {
  id?: string;
  name: string;
  type: string;
  value: number;
  recipeId?: string;
}
export interface RateMenu {
  id?: string;
  organization: string;
  cookbookPriority: string[];
}

export type CookbooksFilterValues = {
  menu: string;
  cookbook: string;
  ingredient: string | IngredientType;
  baseIngredient: string | IngredientType;
  sideIngredient: string | IngredientType;
};

export type ViewMode = "detailed" | "compact" | "collapsed" | "table";
