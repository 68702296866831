import { fetchCookbook, RatesFormData } from "@busie/api";
import { IngredientGroup, Recipe, RuleType } from "@busie/utils";
import { centsToDollars } from "@busie/utils";

import {
  initialIngredient,
  IngredientOption,
  baseIngredientsList,
  sideIngredientsList,
  initialFormData,
} from "./initialState";

import cloneDeep from "lodash.clonedeep";

const convertRecipeToIngredientsGroup = (
  recipe: Recipe,
  ingredientsGroup: IngredientOption[]
): IngredientGroup[] => {
  return ingredientsGroup.map((ingredient) => {
    const existingItems = recipe.ingredients.filter((recipeIngredient) => {
      return recipeIngredient.type === ingredient.type;
    });

    return {
      name: ingredient.name,
      type: ingredient.type,
      checked: !!existingItems.length,
      ingredients: existingItems.length
        ? existingItems.map((item) => ({
            ...item,
            value: centsToDollars(Number(item.value)),
            id: "",
            recipeId: "",
          }))
        : [initialIngredient],
    };
  });
};

const convertRuleValueToAccessibleType = (
  type: RuleType,
  value: string | number | Date
): string | number | Date => {
  switch (type) {
    case "DATE":
      return new Date(value);

    default:
      return value;
  }
};

export const convertRecipeToFormData = async (
  authToken: string,
  recipe: Recipe
): Promise<RatesFormData> => {
  const formData: RatesFormData = cloneDeep(initialFormData);

  const cookbook = await fetchCookbook(authToken, recipe.cookbookId);

  formData.name = `Duplicate of "${recipe.name}"`;
  formData.cookbookId = recipe.cookbookId;
  formData.cookbookAssignmentType = cookbook._type;
  formData.ruleType = recipe.rule.type;
  formData.ruleTypeValue[recipe.rule.type] = convertRuleValueToAccessibleType(
    recipe.rule.type,
    recipe.rule.value
  );
  formData.ingredientList.base = convertRecipeToIngredientsGroup(
    recipe,
    baseIngredientsList
  );
  formData.ingredientList.side = convertRecipeToIngredientsGroup(
    recipe,
    sideIngredientsList
  );

  return formData;
};
