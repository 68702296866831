import {
  Button,
  DoneIcon,
  FlexContainer,
  H3,
  NoteText,
  Subtitle,
} from "@busie/ui-kit";
import { observer } from "mobx-react";
import FormContainerStyled from "../styled/FormContainerStyled";
import { centsToDollars, currencyFormatter } from "@busie/utils";
import { useState } from "react";

interface Props {
  onClose?: () => void;
  onCreateQuote: () => void;
  tripPrice: number;
  showPrice?: boolean;
}

export const TripCreatedBlock: React.FC<React.PropsWithChildren<Props>> =
  observer(
    ({
      onClose: handleOnClose,
      tripPrice,
      onCreateQuote,
      showPrice = true,
    }: Props) => {
      const [isLoading, setIsLoading] = useState(false);

      const onClickCreateQuote = async () => {
        setIsLoading(true);
        onCreateQuote();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      };

      return (
        <FormContainerStyled sx={{ padding: "20px" }}>
          <FlexContainer columnGap={2} justify="space-between" align="center">
            <FlexContainer columnGap={2}>
              <DoneIcon />
              <Subtitle className="title">New Trip Created</Subtitle>
            </FlexContainer>
            {showPrice && (
              <FlexContainer direction="column">
                <div style={{ textAlign: "right" }}>
                  <NoteText>Trip Subtotal</NoteText>
                  <H3 margin="0">
                    {currencyFormatter(
                      centsToDollars(Math.ceil(Number(tripPrice)))
                    )}
                  </H3>
                </div>
              </FlexContainer>
            )}

            <Button
              loading={isLoading}
              typestyle="primary"
              onClick={onClickCreateQuote}
            >
              Create Quote
            </Button>

            {handleOnClose && (
              <Button onClick={handleOnClose}>Close Window</Button>
            )}
          </FlexContainer>
        </FormContainerStyled>
      );
    }
  );
