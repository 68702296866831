import React from "react";
import { observer } from "mobx-react-lite";

import { centsToDollars, currencyFormatter, metersToMiles } from "@busie/utils";

import EditButton from "./EditButton";
import PropertyString from "../PropertyString";

import FormContainer from "../FormContainer";

import DeparturesForm from "../../store/departuresForm";
import { TripLegsDetails } from "../../../TripLegsDetails";
import { Subtitle } from "@busie/ui-kit";

interface Props {
  setNextStep: (step: number) => void;
  setCurrentStep: (step: number) => void;
  departuresForm: DeparturesForm;
  // TODO: REFACTOR
  isEmbedded?: boolean;
}
const RouteInformationBlock: React.FC<React.PropsWithChildren<Props>> =
  observer(
    ({ departuresForm, setCurrentStep, setNextStep, isEmbedded }: Props) => {
      const form = departuresForm;
      if (!form.route) return <div />;

      return (
        <FormContainer
          title="Route information"
          controls={
            <EditButton
              onClick={() => {
                setNextStep(4);
                setCurrentStep(2);
              }}
              aria-label="edit route information"
            />
          }
        >
          {form.trip?._id && (
            <>
              <PropertyString name="Trip distance">
                {metersToMiles(form.trip._meters) || 0} mi
              </PropertyString>
              <PropertyString name="Total live miles">
                {metersToMiles(form.trip._liveMeters) || 0} mi
              </PropertyString>
              <PropertyString name="Total dead head miles">
                {metersToMiles(form.trip._deadHeadMeters) || 0} mi
              </PropertyString>
            </>
          )}
          <PropertyString name="Quoted price">
            {!isEmbedded || !form.trip?.hideInstantQuote
              ? currencyFormatter(
                  centsToDollars(Math.ceil(Number(form.estimatedPrice)))
                )
              : "We cannot provide an instant quote at this time, but we've captured your trip details and will contact you shortly."}
          </PropertyString>

          <Subtitle margin="40px 0 0 ">Legs information</Subtitle>
          {form.trip && (
            <TripLegsDetails trip={form.trip} isEmbedded={isEmbedded} />
          )}
        </FormContainer>
      );
    }
  );
export default RouteInformationBlock;
