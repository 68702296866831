import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useAuth0 } from "@auth0/auth0-react";
import { createQuote, createQuoteAsGuest, CreateQuoteData } from "@busie/api";
import { history } from "@busie/utils";

import store from "../../store";

import CustomerInformationBlock from "./CustomerInformationBlock";
import TripInformationBlock from "./TripInformationBlock";
import RouteInformationBlock from "./RouteInformationBlock";
import DeparturesBlock from "./DeparturesBlock";
import MapRouteBlock from "./MapRouteBlock";
import OnSubmitMessageBlock from "./OnSubmitMessageBlock";
import FormContainer from "../FormContainer";

import Button from "../Button";
import { useAmplitude } from "@busie/core";
import { TripCreatedBlock } from "./TripCreatedBlock";

interface Props {
  setCurrentStep: (step: number) => void;
  setNextStep: (step: number) => void;
  quotesAuthToken: string;
  rateServiceAuthToken: string;
  guestAuthToken?: string;
  embedded?: boolean;
  embeddedFormOrgId?: string;
  onClose?: () => void;
}

const SummaryBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  (props: Props) => {
    const { user } = useAuth0();
    const { track } = useAmplitude();
    const [showOnSubmitMessage, setShowOnSubmitMessage] =
      React.useState<boolean>(false);

    useEffect(() => {
      const legs = store.departuresForm.trip?._legs || [];
      if (!((props.rateServiceAuthToken || props.guestAuthToken) && legs))
        return;

      const updateLegPriceMap = async () => {
        for (const leg of legs) {
          await store.departuresForm.updateLegPriceMap(
            leg,
            {
              rateService: props.rateServiceAuthToken,
              guest: props.guestAuthToken,
            },
            props.embedded
          );
        }
      };
      updateLegPriceMap();

      return () => {
        store.departuresForm.clearLegPriceMap();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rateServiceAuthToken, props.guestAuthToken]);

    const submitInappForm = async (requestBody: CreateQuoteData) => {
      await createQuote(props.quotesAuthToken, requestBody);
      track("quote created");
      await store.resetAll();
      await history.push("/");
    };

    const submitEmbeddedForm = async (requestBody: CreateQuoteData) => {
      await createQuoteAsGuest(props.guestAuthToken || "", requestBody);
      track("quote created");
      await setShowOnSubmitMessage(true);
    };
    const handlerOnSubmit = async () => {
      const requestBody = {
        // NOTE: dispatch id should always come from the trip.
        locationId:
          store.departuresForm.trip?._dispatchId ||
          "COULD_NOT_FIND_DISPATCH_ID",
        // NOTE: slight nuance here, but we need to get the organizationId from the trip as in the case of a network quote it could be different.
        organizationId:
          store.departuresForm.trip?._organizationId ||
          (user ? user?.org_id : props.embeddedFormOrgId),
        startDate: store.departuresForm.trip?._legs[0]._departureDateTime || "",
        customerGroupId: store.customerForm.customerGroup?.id || "",
        contactEmail: store.customerForm.customer?.email || "",
        contactName: store.customerForm.customer?.name || "",
        contactId: store.departuresForm.trip?._mainContactId || "",
        customerGroupName: store.customerForm.customerGroup?.name || "",
        experienceId: store.departuresForm.trip?._id || "",
        price: Math.ceil(store.departuresForm.estimatedPrice || 0),
        // NOTE: more nuance - if there is an integration id, we need to specify it.
        quotesIntegrationId:
          store.departuresForm.trip?._integrationId || undefined,
        // NOTE: in the event that there is a network related to the trip, it should be specified as well as the source id
        sourceId: store.departuresForm.trip?._networkId || undefined,
      };

      props.embedded
        ? await submitEmbeddedForm(requestBody)
        : await submitInappForm(requestBody);
    };

    if (showOnSubmitMessage) {
      return <OnSubmitMessageBlock />;
    }

    return (
      <>
        {props.embedded && (
          <FormContainer title="Important!">
            <p>
              In order to ensure we receive your quote request, please click the
              "Create quote" button. Thank you!
            </p>
          </FormContainer>
        )}
        <TripCreatedBlock
          tripPrice={store.departuresForm.estimatedPrice}
          onClose={props.onClose}
          showPrice={
            !props.embedded || !store.departuresForm.trip?.hideInstantQuote
          }
          onCreateQuote={handlerOnSubmit}
        />
        <CustomerInformationBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4);
            props.setCurrentStep(0);
            store.customerForm.setEditMode(true);
          }}
          customerForm={store.customerForm}
        />
        <TripInformationBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4);
            props.setCurrentStep(1);
            store.tripForm.setEditMode(true);
          }}
          tripForm={store.tripForm}
        />
        <RouteInformationBlock
          {...props}
          departuresForm={store.departuresForm}
          isEmbedded={props.embedded}
        />
        <MapRouteBlock {...props} departuresForm={store.departuresForm} />
        <DeparturesBlock {...props} departuresForm={store.departuresForm} />

        {!store.quoteId && (
          <Button fullWidth async onClick={handlerOnSubmit}>
            Create quote
          </Button>
        )}
      </>
    );
  }
);

export default SummaryBlock;
