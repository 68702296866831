import cx from "classnames";

import { Leg } from "@busie/api";
import {
  CalculateIcon,
  DrawerDetailsBlock,
  FlexContainer,
  H3,
  IconButton,
  Loading,
  PathIcon,
  TimeIcon,
  palette,
} from "@busie/ui-kit";
import React from "react";
import { BlockContainer } from "~/QuotesAndBookingsPage/shared/ui";
import {
  DATE_CUSTOM_FORMAT,
  getLegAddressDisplay,
  getTravelTimeDisplay,
} from "../../model";
import {
  DispatchLocation,
  centsToDollars,
  currencyFormatter,
  dayjsExt,
  metersToMiles,
} from "@busie/utils";

interface Props {
  legs: Leg[];
  isLoading: boolean;
  dispatchLocation?: DispatchLocation;
}

export const Legs: React.FC<React.PropsWithChildren<Props>> = ({
  legs,
  isLoading,
  dispatchLocation,
}) => {
  // TODO: Leg Price Breakdown
  return (
    <DrawerDetailsBlock name="Legs" collapsible>
      {legs.map((leg, index) => (
        <FlexContainer
          key={`leg-${index}`}
          justify="space-evenly"
          align="center"
          fullWidth
        >
          <BlockContainer sx={{ width: "100%" }}>
            <FlexContainer
              direction="row"
              justify="space-between"
              wrap="wrap"
              fullWidth
              align="center"
              sx={{ marginBottom: "13px" }}
            >
              <H3>{`Leg ${index + 1}`}</H3>

              <FlexContainer direction="row" align="center" columnGap={1}>
                <PathIcon />
                <H3>{`${metersToMiles(leg.METERS)} mi`}</H3>
              </FlexContainer>

              <FlexContainer direction="row" align="center" columnGap={1}>
                <TimeIcon />
                <H3>{`~${getTravelTimeDisplay(leg.HOURS)}`}</H3>
              </FlexContainer>

              <FlexContainer direction="row" align="center" columnGap={1}>
                <H3>
                  {currencyFormatter(
                    centsToDollars(
                      leg.LEG_PRICE.reduce(
                        (acc, curr) => acc + curr.SUBTOTAL,
                        0
                      )
                    )
                  )}
                </H3>
                <IconButton icon={<CalculateIcon />} />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer justify="space-evenly" columnGap={2}>
              <div
                className={cx({
                  departures: true,
                  end: false,
                  start: true,
                  last: false,
                  first: true,
                })}
                style={{ width: "100%" }}
              >
                {isLoading || !dispatchLocation ? (
                  <Loading />
                ) : (
                  <>
                    <div className={cx({ origin: true, start: true })}>
                      {getLegAddressDisplay(
                        leg.START_LOCATION.ADDRESS,
                        dispatchLocation,
                        index === 0
                      )}
                      <div
                        style={{
                          marginTop: "4px",
                          color: palette?.black?.plus30,
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Departing:{" "}
                        {dayjsExt(leg.DEPARTURE_DATETIME).format(
                          DATE_CUSTOM_FORMAT
                        )}
                      </div>
                    </div>

                    <div className={cx({ destination: true, end: false })}>
                      {getLegAddressDisplay(
                        leg.DESTINATION_LOCATION.ADDRESS,
                        dispatchLocation,
                        index === legs.length - 1
                      )}
                      <div
                        style={{
                          marginTop: "4px",
                          color: palette?.black?.plus30,
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Estimated Arrival:{" "}
                        {dayjsExt(leg.ARRIVAL_DATETIME).format(
                          DATE_CUSTOM_FORMAT
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </FlexContainer>
          </BlockContainer>
        </FlexContainer>
      ))}
    </DrawerDetailsBlock>
  );
};
