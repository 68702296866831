import React from "react";
import { observer } from "mobx-react-lite";

import RateMenuPageStore from "~/rates/RateMenuPage/store";

import { Button, AddIcon } from "@busie/ui-kit";

import IngredientForm from "~/rates/RateMenuPage/components/CreateRecipeModal/IngredientForm";
import IngredientGroupFormStyled from "~/rates/RateMenuPage/components/CreateRecipeModal/styled/IngredientGroupFormStyled";

import {
  IngredientGroup as IIngredientGroup,
  Ingredient,
  IngredientListType,
} from "@busie/utils";
interface Props {
  groupName: IngredientListType;
  groupIndex: number;
  group: IIngredientGroup;
}

const IngredientGroupForm: React.FC<React.PropsWithChildren<Props>> = observer(
  (props) => {
    const store = RateMenuPageStore.createRecipeModal;
    const ingredientGroupStore =
      store.formData.ingredientList[props.groupName][props.groupIndex];

    const addIngredient = () => {
      store.addIngredient(props.groupName, props.groupIndex);
    };
    const removeIngredient = (index: number) => {
      store.removeIngredient(props.groupName, props.groupIndex, index);
    };
    const updateValue = (
      index: number,
      key: keyof Ingredient,
      value: string
    ) => {
      store.updateIngredientValue(
        props.groupName,
        props.groupIndex,
        index,
        key,
        value
      );
    };

    return (
      <IngredientGroupFormStyled>
        {ingredientGroupStore.ingredients.map((item: Ingredient, i: number) => {
          return (
            <IngredientForm
              key={i}
              index={i}
              values={item}
              onPriceUpdate={(index, value) =>
                updateValue(index, "value", value)
              }
              onNameUpdate={(index, value) => updateValue(index, "name", value)}
              showRemoveButton={ingredientGroupStore.ingredients.length > 1}
              onRemove={() => removeIngredient(i)}
            />
          );
        })}
        <div className="controls">
          {ingredientGroupStore.ingredients.length < 5 && (
            <Button
              startIcon={<AddIcon />}
              onClick={addIngredient}
              size="small"
            >
              Add ingredient
            </Button>
          )}
          <span className="attention-badge">
            <span role="img" aria-label="note">
              ☝️
            </span>{" "}
            Maximum 5 ingredients can be added.
          </span>
        </div>
      </IngredientGroupFormStyled>
    );
  }
);
export default IngredientGroupForm;
